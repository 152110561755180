import React from "react";

import imgM from "../../assets/image/attendance-system/png/meeting-2.png";
import imgDP from "../../assets/image/attendance-system/png/dot-pattern-black.png";
import imgLC from "../../assets/image/attendance-system/png/left-circlehalf-shape.png";
import {AnchorLink} from 'gatsby-plugin-anchor-links';

const Content3 = ({ className, ...rest }) => {
  return (
    <>
      <div className={className} {...rest}>
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 col-md-9 col-xs-11">
              <div
                className="section-title"
                data-aos="fade-right"
                data-aos-delay={500}
                data-aos-once="true"
              >
                <h2 className="font-size-10 mb-10 mb-lg-20 text-right text-rtl mt-4">
                  ProperTime בכמה מילים...
                </h2>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            {/* Right */}
            <div
              className="col-xl-6 col-lg-6 col-md-5 col-xs-8 force-ltr"
              data-aos="fade-left"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="content-image-group-3 mb-17 mb-lg-0 ml-xl-16">
                <div className="img-1">
                  <img className="w-100" src={imgM} alt="" />
                  <div className="img-2">
                    <img className="w-100" src={imgDP} alt="" />
                  </div>
                  <div className="img-3 rounded-10 force-left-0">
                    <img className="w-100 opacity-7" src={imgLC} alt="" />
                  </div>
                </div>
              </div>
            </div>
            {/* Right */}
            {/* Left */}
            <div
              className="col-lg-6 col-md-7 col-xs-10"
              data-aos="fade-right"
              data-aos-delay={500}
              data-aos-once="true"
            >
              <div className="ml-lg-10 ml-xl-25 text-right text-rtl">
                <p className="heading-default-color font-size-6 mb-10">
                  מותג ProperTime מציע מערכת שעון נוכחות מבית קבוצת 'פרומתאוס', המספקת מגוון פתרונות לניהול עובדים, היעדרויות ומשימות. הקבוצה הוקמה בשנת 2007 על ידי אורי שמחוני, מומחה לפיתוח מערכות מתקדמות וחכמות לארגונים. כיום מאות עסקים וארגונים מרחבי הארץ נהנים ממערכות הקבוצה, המכילות טכנולוגיות מתקדמות ופיצ'רים המתכתבים עם העידן העסקי החדש.
                </p>
                <div className="btn-group mb-12">
                  <AnchorLink className="btn btn-dodger-blue-2 rounded-5"
                              to={'/attendance-system/#signup'}
                              onAnchorLinkClick={() => setTimeout(() => {
                                document.getElementById('firstName').focus()
                              }, 500)}>
                    התנסות במערכת ללא התחייבות
                  </AnchorLink>
                </div>
              </div>
            </div>
            {/* Left End */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Content3;
