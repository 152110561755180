import React from 'react';

import DashBoardImageWebP from '../../assets/image/attendance-system/webp/propertime-timesheet.webp';
import DashBoardImagePng from '../../assets/image/attendance-system/png/propertime-timesheet.png';
import YellowShape from '../../assets/image/attendance-system/png/yellow-shape.png';
import GreenShape from '../../assets/image/attendance-system/png/green-shape.png';
import {AnchorLink} from 'gatsby-plugin-anchor-links';

const Hero = ({className, ...rest}) => {
    return (
        <div className={className} {...rest}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-xl-7 col-lg-7 col-md-9">
                        <div
                            className="text-center mb-11 mb-lg-24"
                            data-aos="zoom-in"
                            data-aos-delay={500}
                        >
                            <h1 className="font-size-11 mb-7">
                                מערכת נוכחות חכמה<br />
                                והניהול פשוט יותר
                            </h1>
                            <p className="font-size-7 px-lg-5 mb-0">
                                מערכת נוכחות עובדים שנותנת לך שליטה מלאה!
                            </p>
                            <div className="btn-group d-inline-block mt-10">
                                <AnchorLink className="btn btn-blue-3 btn-2 rounded-5"
                                            to={'/attendance-system/#signup'}
                                            onAnchorLinkClick={() => setTimeout(() => {
                                                document.getElementById('firstName').focus()
                                            }, 500)}>
                                    14 ימי ניסיון, בוא נתחיל >
                                </AnchorLink>
                                <p className="d-block mt-6 font-size-3 mb-0 text-bali-gray letter-spacing-reset">
                                    אין צורך בכרטיס אשראי
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Hero Image Group */}
                <div className="row justify-content-center">
                    <div className="col-md-10 col-xs-11">
                        <div className="l3-hero-image-group w-100">
                            {/* Image One */}
                            <div
                                className="img-1 "
                                data-aos="fade-up"
                                data-aos-delay={500}
                                data-aos-once="true"
                            >
                                <picture>
                                    <source type="image/webp" srcSet={DashBoardImageWebP}/>
                                    <source type="image/png" srcSet={DashBoardImagePng}/>
                                    <img src={DashBoardImagePng} alt=""/>
                                </picture>
                                {/* Image Two */}
                                <div className="img-2">
                                    <img
                                        src={YellowShape}
                                        alt=""
                                        data-aos="fade-right"
                                        data-aos-delay={700}
                                        data-aos-once="true"
                                    />
                                </div>
                                {/* Image Three */}
                                <div className="img-3">
                                    <img
                                        src={GreenShape}
                                        alt=""
                                        data-aos="fade-left"
                                        data-aos-delay={900}
                                        data-aos-once="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Hero Image Group */}
            </div>
        </div>
    );
}


export default Hero;
