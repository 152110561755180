import React from "react";
import imageOne from "../../assets/image/attendance-system/png/content-2-img.png";
import {AnchorLink} from 'gatsby-plugin-anchor-links';

const ContentThree = ({ className, ...rest }) => {
  return (
      <div className={className} {...rest}>
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div className="col-xl-6 col-lg-7 col-md-9 order-2 order-lg-1">
              <div
                  className="mt-13 mt-lg-0 dark-mode-texts text-right text-rtl"
                  data-aos="fade-right"
                  data-aos-delay={500}
                  data-aos-once="true"
              >
                <h6 className="font-size-3 text-dodger-blue-1 text-uppercase mb-9 letter-spacing-normal">
                  התאמה מלאה לנהלי רשות החדשנות
                </h6>
                <h2 className="font-size-10 mb-8 letter-spacing-n83">
                  מערכת נוכחות המאושרת על ידי המדען הראשי
                </h2>
                <p className="font-size-7 mb-0">
                  מערכת נוכחות שעות עובדים ProperTime היא הראשונה שאושרה לדיווח במובייל מטעם רשות החדשנות ועומדת בכללי דיווח השעות לחברות שקיבלו אישור למענקי המדען הראשי. להכרה במענקים ולניצול מלא של כספי המדען, חובה להשתמש במערכת דיווח נוכחות המאושרת ע"י רשות החדשנות, שתמנע פסילת שעות דיווח ונזק כספי עצום. מלבד זאת, המערכת תומכת בחתימה דיגיטלית מאובטחת לניהול יעיל של כל  תהליך חתימות העובדים והמנהלים, וזאת על פי הדו"חות הנדרשים ע"י הרשות לחדשנות. פרמטר זה חוסך לך התעסקות עם ניירת, חתימות והעברת הדו"חות.
                </p>
                <div className="mt-12">
                  <AnchorLink className="btn btn-sunset btn-xl h-55 rounded-5"
                              to={'/attendance-system/#signup'}
                              onAnchorLinkClick={() => setTimeout(() => {
                                document.getElementById('firstName').focus()
                              }, 500)}>
                    התחל עם 14 ימי ניסיון
                  </AnchorLink>
                </div>
              </div>
            </div>
            {/* Right Image */}
            <div className="col-xl-5 offset-xl-1 col-lg-5 col-md-8 col-xs-11 order-1 order-lg-2">
              <div
                  className="l4-content-img-2 ml-lg-10 ml-xl-7 rounded-10 text-center"
                  data-aos="fade-left"
                  data-aos-duration={600}
                  data-aos-delay={500}
                  data-aos-once="true"
              >
                <img
                    className="w-75 w-md-100"
                    src={imageOne}
                    alt=""
                />
                <div className="image-card w-fit-content bg-white rounded-15 d-flex align-items-center shadow-10 p-8">
                  <div className="ml-6 square-59 bg-dodger-blue-1-op1 rounded-10 text-dodger-blue-1 font-size-7">
                    <i className="fas fa-bell" />
                  </div>
                  <div className="text-rtl text-right">
                    <h4 className="font-size-6 text-dark-cloud mb-0 line-height-26">
                      הראשונה!
                    </h4>
                    <p className="text-rtl text-right font-size-5 text-stone mb-0">
                      לעמוד בכל נהלי רשות החדשנות
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* End Right Image */}
          </div>
        </div>
      </div>
  );
};

export default ContentThree;
