import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/attendance-system/Hero";
import Company from "../sections/attendance-system/Company";
import Feature from "../sections/attendance-system/Feature";
import ContentThree from "../sections/attendance-system/ContentThree";
// import ContentOne from "../sections/attendance-system/ContentOne";
// import ContentTwo from "../sections/attendance-system/ContentTwo";
import Review from "../sections/attendance-system/Review";
// import Pricing from "../sections/attendance-system/Pricing";
import Contact from '../sections/attendance-system/Contact';
import SEO from "../components/seo"
import Progress from '../sections/attendance-system/Progress';
import Content3 from '../sections/attendance-system/Content3';

const AttendanceSystem = ({location}) => {
  return (
    <>
      <SEO title="מערכת נוכחות חכמה והניהול פשוט יותר" location={location}
           meta={[{name: 'keywords', content: 'מערכת נוכחות,מערכת נוכחות עובדים,מערכת נוכחות והיעדרות,נוכחות,היעדרות,נוכחות עובדים,מערכת שעון נוכחות עובדים'}]}
      description={`מערכת נוכחות עובדים פרופרטיים - מערכת נוכחות חכמה והניהול פשוט יותר, נותנת לך שליטה מלאה! עם מערכת נוכחות ProperTime קל לנהל נוכחות עובדים ומשימות בצורה מדויקת ואפקטיבית. הטמעת המערכת קלה במיוחד בכל ארגון ושירותי התמיכה זמינים בשבילך תמיד! המערכת מאושרת לדיווח מטעם המדען הראשי והראשונה שאושרה לדיווח מהמובייל.`}/>
      <PageWrapper themeConfig={{
          footerStyle: "style3",
          footerClassName: "pt-13 pt-lg-25 pb-13 pb-lg-10",
      }}>
        <Hero className="bg-default-3 pt-25 pt-lg-29" />
        <Company className="pt-12 pb-9" />
        <Feature className="pt-4 pt-lg-18 pb-15 pb-lg-25 border-bottom border-default-color-3" />
          <ContentThree className="bg-dark-cloud pt-15 pt-lg-30 pb-15 pb-lg-26" />
          <Progress className="pb-lg-21 border-top border-default-color-1" />
          <Review className="bg-default-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
          <Content3 className="pb-12 pb-lg-25" />
          <Contact className="bg-gradient-3 pt-13 pt-lg-25 pb-9 pb-lg-25" />
      </PageWrapper>
    </>
  );
};
export default AttendanceSystem;
